import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Box} from '@mui/material';

const ErrorPage = () => {
    useEffect(() => {
        document.title = 'Error';
    });

    return (
        <Box className='error-page-container'>
            <div>
                <h3>Something's Not Right</h3>
            </div>

            <div>
                The page you are trying to reach can't be found. Maybe go back <Link to='/'>home</Link>?
            </div>
        </Box>
    )
}

export default ErrorPage;
