import {useEffect} from 'react';
import {Box} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ImageGallery from 'react-image-gallery';

import Footer from '../../components/Footer/Footer';
import '../../fonts/Merriweather-Regular.ttf';
import '../../fonts/Merriweather-Bold.ttf';

const useStyles = makeStyles()(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Merriweather',
        margin: '20px',

        '& p': {
            marginTop: 0,
            textAlign: 'center'
        }
    },


    imageGalleryContainer: {
        marginTop: '15px'
    },
}));

const BigDayPhotos = () => {
    const { classes, cx } = useStyles();

    const images = [
        {
            original: process.env.PUBLIC_URL + '/images/ceremony_1.jpg'
        },
        {
            original: process.env.PUBLIC_URL + '/images/ceremony_2.jpg'
        },
        {
            original: process.env.PUBLIC_URL + '/images/ceremony_3.jpg'
        },
        {
            original: process.env.PUBLIC_URL + '/images/ceremony_4.jpg'
        },
        {
            original: process.env.PUBLIC_URL + '/images/ceremony_5.jpg'
        }
    ]

    const footerItems = [
        {
            to: '/big-day',
            text: 'Big Day'
        }
    ]

    useEffect(() => {
        document.title = 'Chris Tybur - Big Day Photos';
    });

    return (
        <Box>
            <Box className={cx(classes.mainContainer)}>
                <p>
                    The ceremony was done at the top of the Seattle municipal courthouse downtown. We lucked out that it didn't rain.
                </p>

                <p>
                    Our witnesses were Kim's sister Paige and her partner Brad.
                </p>

                <div className={cx(classes.imageGalleryContainer)}>
                    <ImageGallery items={images} showPlayButton={false} />
                </div>
            </Box>

            <Footer items={footerItems} />
        </Box>
    )
}

export default BigDayPhotos;
