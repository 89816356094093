import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Box, Card, Paper} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Footer from '../../components/Footer/Footer';
import '../../fonts/Merriweather-Regular.ttf';
import '../../fonts/Merriweather-Bold.ttf';

const useStyles = makeStyles()(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Merriweather',
        width: '100%',

        '& a:link, a:visited, a:focus, a:hover, a:active': {
            color: '#1976d2',
            textDecoration: 'none'
        }
    },

    mainPaper: {
        marginTop: '60px',
        padding: '60px',
        width: '50%',

        '@media (max-width: 1000px)': {
            padding: '20px',
            width: '80%'
        },

        '@media (max-width: 767px)': {
            marginTop: '20px'
        }
    },

    defaultCard: {
        marginBottom: '60px',
        padding: '30px',

        '@media (max-width: 800px)': {
            marginBottom: '30px',
            padding: '15px'
        }
    },

    header: {
        textAlign: 'center'
    },

    image: {
        width: '100%',
        height: 'auto'
    },

    primaryHeaderText1: {
        marginBottom: 0
    },

    primaryHeaderText2: {
        marginBottom: 0,
        marginTop: '8px'
    },

    secondaryHeaderText: {
        marginBottom: 0
    },

    pictureLink: {
        textAlign: 'center'
    },
}));

const BigDay = () => {
    const { classes, cx } = useStyles();

    useEffect(() => {
        document.title = 'Chris Tybur - Big Days';
    });

    return (
        <Box>
            <Box className={cx(classes.mainContainer)}>
                <Paper className={cx(classes.mainPaper)} elevation={4}>
                    <Card elevation={4} className={`${cx(classes.header)} ${cx(classes.defaultCard)}`}>
                        <img src={process.env.PUBLIC_URL + "/images/big_day_header.jpg"} className={cx(classes.image)} alt="The ceremony"/>

                        <h2 className={cx(classes.primaryHeaderText1)}>Kim MacLeod and Chris Tybur</h2>
                        <h2 className={cx(classes.primaryHeaderText2)}>Marriage celebration</h2>
                    </Card>

                    <Card elevation={4} className={cx(classes.defaultCard)}>
                        <div>
                            Our marriage celebration on 8/4/2022 was a blast! Thanks to all who attended.
                        </div>
                        <br/>

                        <div>
                            We are working on collecting the best photos and video from that night and posting it online. We'll let people know
                            when they are ready.
                        </div>
                    </Card>

                    <div className={cx(classes.pictureLink)}>
                        We have some <Link to='/big-day/photos'>photos</Link> of the wedding ceremony in 2021 for your viewing pleasure.
                    </div>
                </Paper>
            </Box>

            <Footer />
        </Box>
    )
}

export default BigDay;
